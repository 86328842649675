import personShark from '../../assets/image/person-shark.webp';
import personJumpFallsImage from '../../assets/image/person-jump-falls.webp';
import sandbarImage from '../../assets/image/sandbar.webp';
import peakImage from '../../assets/image/peak.jpg';
import canyoneeringImage from '../../assets/image/canyoneering.webp';
import statueImage from '../../assets/image/lapu-lapu-statue.webp';
import simalaImage from '../../assets/image/simala.webp';
import turtleImage from '../../assets/image/turtle.webp';
import scubaImage from '../../assets/image/scuba.webp';

const toursData = {
    tours: [
      {
        id: 1,
        tour_type: 'countryside',
        title: 'Whale Shark Snorkeling + Kawasan Falls + Pescador Island with Sardines & Turtle',
        location: 'Oslob, Cebu, Philippines',
        badge: 'Top Rated',
        price: '₱3,500.00/pax',
        image: personShark,
        buttonText: 'Book Now',
      },
      {
        id: 2,
        tour_type: 'joiner',
        title: 'Whale Shark Snorkeling + Badian Canyoneering in Kawasan Falls',
        location: 'Badian, Cebu, Philippines',
        badge: 'Customer Favorite',
        price: '₱4,800.00/pax',
        image: personJumpFallsImage,
        buttonText: 'Book Now',
      },
      {
        id: 3,
        tour_type: 'island',
        title: 'Whale Shark Snorkeling + Sumilon Sandbar',
        location: 'Sumilon, Oslob, Cebu, Philippines',
        badge: 'Top Rated',
        price: '₱2,900.00/pax',
        image: sandbarImage,
        buttonText: 'Book Now',
      },
      // {
      //   id: 4,
      //   tour_type: 'firefly',
      //   title: 'Canyoneering with Kawasan Falls and Moalboal + Osmena Peak Tour ( with transportation)',
      //   location: 'Mantalongon, Dalaguete, Cebu, Philippines',
      //   badge: 'Highly Rated',
      //   price: '₱0.00',
      //   image: peakImage,
      //   buttonText: 'Book Now',
      // },
      // {
      //   id: 5,
      //   tour_type: 'firefly',
      //   title: 'Canyoneering and Kawasan Falls with Moalboal Day Tour',
      //   location: 'Moalboal, Cebu, Philippines',
      //   badge: 'Highly Rated',
      //   price: '₱0.00',
      //   image: canyoneeringImage,
      //   buttonText: 'Book Now',
      // },
      {
        id: 6,
        tour_type: 'firefly',
        title: 'Twin City Tour (Cebu & Lapu-Lapu City) with Uphill Spots',
        location: 'Cebu, Philippines',
        badge: 'Highly Rated',
        price: '₱1,500.00/pax',
        image: statueImage,
        buttonText: 'Book Now',
      },
      {
        id: 7,
        tour_type: 'firefly',
        title: 'Simala Church & Cebu City Tour',
        location: 'Cebu, Philippines',
        badge: 'Highly Rated',
        price: '₱1,500.00/pax',
        image: simalaImage,
        buttonText: 'Book Now',
      }, 
      {
        id: 8,
        tour_type: 'firefly',
        title: 'Whale Shark Snorkeling + Kawasan Falls + Pescador Island with Sardines & Turtle',
        location: 'Oslob, Cebu, Philippines',
        badge: 'Highly Rated',
        price: '₱3,500.00/pax',
        image: turtleImage,
        buttonText: 'Book Now',
      },  
      {
        id: 9,
        tour_type: 'firefly',
        title: 'Whale Shark Snorkeling + Scuba Diving',
        location: 'Oslob, Cebu, Philippines',
        badge: 'Highly Rated',
        price: '₱4,650.00/pax',
        image: scubaImage,
        buttonText: 'Book Now',
      },                     
    ]
  };

export default toursData;

