import panglaoImage from '../../assets/image/panglao.webp';
import countrySide from '../../assets/image/country-side.webp';
import fireflyImage from '../../assets/image/firefly-lights.webp';
import caveImage from '../../assets/image/hinagdanan-cave.webp';
import islandHoppingImage from '../../assets/image/balicasag-island.webp';
import kayakImage from '../../assets/image/kayak.webp';
import andaImage from '../../assets/image/anda.webp';
import whaleImage from '../../assets/image/whale.webp';


const toursData = [
    {
      id: 1,
      tour_type: 'countryside',
      title: 'Bohol Countryside Tour',
      location: 'Bohol, Philippines',
      description: `
        Experience the charm of Bohol’s countryside with a tour that showcases the island's natural beauty and
        rich cultural heritage. From the iconic Chocolate Hills and the tranquil Loboc River cruise to the
        captivating Tarsier Sanctuary and historic Baclayon Church, this tour immerses you in the best of Bohol’s
        landscapes and local life. Enjoy lush greenery, scenic vistas, and the warm hospitality of the Boholanos
        as you explore one of the Philippines' most enchanting destinations.
        Our Bohol Countryside Tour package will take you to Bohol’s most popular tourist destinations such as
        the world-famous Chocolate Hills, Loboc River, Man-Made Forest, Tarsier Sanctuary, Blood Compact
        Shrine, and many more.      
      `,
      image: countrySide,
      price: `₱850.00`,
      bookings: '1000++ booked',
      details: [
        'Suggested Pick-up Time: 8:00-9:00 AM',
        'Tour Duration: 8-9 hours',
        'This rate only applies if your hotel is within the Panglao or Tagbilaran areas'        
      ],
      pricingPerPax: [
        { pax: '1 PAX', price: '₱4,500.00' },
        { pax: '2 PAX', price: '₱2,600.00' },
        { pax: '3 PAX', price: '₱2,400.00' },
        { pax: '4 PAX', price: '₱2,200.00' },
        { pax: '5 PAX', price: '₱1,980.00' },
        { pax: '6 PAX', price: '₱1,880.00' },
        { pax: '7 PAX', price: '₱1,680.00' },
        { pax: '8 PAX', price: '₱1,480.00' },
        { pax: '9 PAX', price: '₱1,380.00' },
        { pax: '10 PAX', price: '₱1,280.00'},
        { pax: '11 PAX', price: '₱1,180.00'},
        { pax: '12 PAX', price: '₱980.00'},
        { pax: '13 PAX', price: '₱900.00'},
        { pax: '14 PAX', price: '₱850.00'}
      ],      
      inclusions: [],
      itineraries: [
        'Blood Compact Shrine',
        'Baclayon Church',
        'Loboc/Loay river cruise with buffet lunch on board (optional)',
        'Tarsier Viewing',
        'Man-made forest',
        'Python and butterfly viewing (optional)',
        'Chocolate hills viewing (optional)',
        'Souvenir shop',
        'Mirror of the world (optional)',
      ],
      exclusions: [
        'Lunch @ Loboc River - (₱1,000.00 lunch buffet)',
      ],
      notes: [],
      optional_activities: []
    },
    {
      id: 2,
      tour_type: 'joiner',
      title: 'PANGLAO ISLAND TOUR',
      location: 'Bohol, Philippines',
      description: 'The Panglao Island Land Tour takes you through some of the island’s most charming and culturally rich sites. The tour starts with a visit to Hinagdanan Cave, an impressive underground cave with a natural pool, followed by a stop at the historic Dauis Church, known for its beautiful architecture and peaceful surroundings. Next, at the Bohol Bee Farm, you’ll get a taste of organic farming and enjoy delicious homemade treats. You’ll also visit the fascinating Shell Museum, showcasing a vast collection of rare and unique seashells. The tour concludes at the Panglao Church and its nearby Watchtower, offering a glimpse into the island’s colonial history and heritage.',
      image: caveImage,
      price: `₱1,000.00`,
      bookings: '1500++ booked',
      details: [
        'Suggested Pick-up Time: 8:30-9:00 AM',
        'Tour Duration: 5-6 hours',
        'This rate only applies if your hotel is within the Panglao or Tagbilaran areas'        
      ],
      pricingPerPax: [
        { pax: '1 PAX', price: '₱3,800' },
        { pax: '2 PAX', price: '₱2,250' },
        { pax: '3 PAX', price: '₱1,950' },
        { pax: '4 PAX', price: '₱1,750' },
        { pax: '5 PAX', price: '₱1,550' },
        { pax: '7 PAX', price: '₱1,350' },
        { pax: '8 PAX', price: '₱1,250' },
        { pax: '9 PAX', price: '₱1,150' },
        { pax: '10 PAX', price: '₱1,000' }
      ],      
      inclusions: [
        'Fully Air Conditioned Vehicle w/ Fuel',
        'Entrance Fees',
        'Driver guide',
      ],
      itineraries: [
        'Hinagdanan Cave',
        'Dauis Church',
        'Bohol Bee Farm',
        'Shell Museum',
        'Panglao church and watchtower',
      ],      
      exclusions: [
        'Entrance Fees',
        'Lunch @ Loboc or Loay River Floating Restaurant (Cruise)',
      ],
      notes: [],
      optional_activities: []
    },
    {
      id: 3,
      tour_type: 'island',
      title: 'Panglao Island Hopping',
      location: 'Panglao, Bohol',
      description: '(BALICASAG & VIRGIN ISLAND) The Balicasag & Virgin Island Hopping Tour offers an exciting blend of marine adventure and relaxation. Your day begins with an early morning dolphin-watching excursion, providing a chance to see playful dolphins swimming and leaping in the clear blue waters. Next, you’ll visit Balicasag Island, a top snorkeling and diving spot where you can discover vibrant coral reefs brimming with colorful fish, sea turtles, and other fascinating marine life. The tour’s final destination is the tranquil Virgin Island, known for its stunning white sandbar that appears at low tide. Here, you can relax, wade in the shallow waters, and soak in the island’s breathtaking scenery. This tour is ideal for those looking to experience the best of Bohol’s marine wonders and wildlife.',
      image: islandHoppingImage,
      price: `₱1,280.00`,
      bookings: '2000++ booked',
      details: [
        'Suggested Pick-up Time: 6:00-6:30 AM',
        'Tour Duration: 5-7 hours',
        'This rate only applies if your hotel is within the Panglao or Tagbilaran areas.',
        'Additional charges for transfer will be used if your hotel is far from the starting point.',
        'Starting points: Alona Beach, Solea/Linaw Beach, Amarela Beach, and Dumaluan Beach areas.'        
      ],
      pricingPerPax: [
        { pax: '1 PAX', price: '₱3,800.00' },
        { pax: '2 PAX', price: '₱2,250.00' },
        { pax: '3 PAX', price: '₱1,950.00' },
        { pax: '4 PAX', price: '₱1,750.00' },
        { pax: '5 PAX', price: '₱1,550.00' },
        { pax: '6 PAX', price: '₱1,350.00' },
        { pax: '7 PAX', price: '₱1,250.00' },
        { pax: '8 PAX', price: '₱1,000.00' },
        { pax: '9 PAX', price: '₱1,380.00' },
        { pax: '10 PAX', price: '₱1,280.00'}

      ],      
      inclusions: [
        'Private Motorized Boat',
        'Boatman & Local Guide Fee',
        'Environmental Fee',
        'Life vest & Snorkeling Mask'
      ],
      itineraries: [
        'Dolphin Wathching',
        'Fish Sanctuary & Turtle watching at Balicasag Island',
        'Virgin Island'
      ],
      exclusions: [],
      notes: [
        'Lunch is not included, but there will be an available restaurant on Balicasag.',
        'Island Dolphin watching is by chance only, it’s not 100% sure that dolphins will show up since it’s wildlife mammals and ddepends on the weather too.'
      ],
      optional_activities: []
    },
    {
      id: 4,
      tour_type: 'extreme',
      title: 'Danao Extreme Adventure Tour',
      location: 'Bohol, Philippines',
      description: 'The Danao Extreme Adventure Tour is the ultimate choice for adrenaline enthusiasts seeking a thrilling experience in the heart of Bohol. Located amidst the rugged terrain of Danao Adventure Park, this tour offers a range of intense outdoor activities, including the Suislide—a breathtaking zipline ride that sends you gliding over a dramatic canyon—and the Plunge, a canyon swing that propels you into a thrilling free fall above a stunning gorge. For those craving more excitement, the park also features caving, rappelling, and kayaking along the scenic Wahig River. Surrounded by dramatic cliffs and lush forests, the Danao Extreme Adventure Tour promises a day packed with high-energy adventure and unforgettable moments for thrill-seekers.',
      image: kayakImage,
      price: `₱600.00`,
      bookings: '500++ booked',
      details: [
        'Suggested Pick-up Time: 6:00-7:30 AM',
        'Tour Duration: 8-9 hours',
        'This rate only applies if your hotel is within the Panglao or Tagbilaran areas.'
      ],
      pricingPerPax: [
        { pax: '1 PAX', price: '₱5,500.00' },
        { pax: '2 PAX', price: '₱2,950.00' },
        { pax: '3 PAX', price: '₱2,150.00' },
        { pax: '4 PAX', price: '₱1,900.00' },
        { pax: '5 PAX', price: '₱1,800.00' },
        { pax: '6 PAX', price: '₱1,600.00' },
        { pax: '7 PAX', price: '₱1,400.00' },
        { pax: '8 PAX', price: '₱1,300.00' },
        { pax: '9 PAX', price: '₱1,200.00' },
        { pax: '10 PAX', price: '₱1,100.00'},
        { pax: '11 PAX', price: '₱900.00'},
        { pax: '12 PAX', price: '₱800.00'},
        { pax: '13 PAX', price: '₱700.00'},
        { pax: '14 PAX', price: '₱600.00'}

      ],      
      inclusions: [
        'Private Air-conditioned Vehicle with fuel',
        'Park’s Entrance Fees',
        'Driver Guide'
      ],
      itineraries: [],
      exclusions: [
        'Lunch and Activities',
      ],
      notes: [],
      optional_activities: []
    },
    {
      id: 5,
      tour_type: 'firefly',
      title: 'Firefly Watching Tour',
      location: 'Bohol, Philippines',
      description: 'The Abatan Firefly Watching Tour offers a captivating evening journey along the tranquil Abatan River. As dusk settles, you’ll embark on a boat ride through calm waters, where the riverbanks come alive with the soft glow of thousands of fireflies, creating a mesmerizing display. Guided by an expert, you’ll learn fascinating details about the fireflies’ life cycle and the diverse ecosystem of the river. The peaceful ambiance and the gentle reflection of firefly lights on the water make this tour an extraordinary experience in Bohol. Ideal for nature enthusiasts and romantics, the Abatan Firefly Watching provides an unforgettable escape into the beauty of nature.',
      image: fireflyImage,
      price: `₱650.00`,
      bookings: '500++ booked',
      details: [
        'Suggested Pick-up Time: 5:30-5:45 AM',
        'Tour Duration: 3-4 hours',
        'This rate only applies if your hotel is within the Panglao or Tagbilaran areas'
      ],
      pricingPerPax: [
        { pax: '1 PAX', price: '₱4,600.00' },
        { pax: '2 PAX', price: '₱2,300.00' },
        { pax: '3 PAX', price: '₱2,100.00' },
        { pax: '4 PAX', price: '₱2,050.00' },
        { pax: '5 PAX', price: '₱1,950.00' },
        { pax: '6 PAX', price: '₱1,650.00' },
        { pax: '7 PAX', price: '₱1,450.00' },
        { pax: '8 PAX', price: '₱1,350.00' },
        { pax: '9 PAX', price: '₱1,250.00' },
        { pax: '10 PAX', price: '₱1,000.00'},
        { pax: '11 PAX', price: '₱950.00'},
        { pax: '12 PAX', price: '₱850.00'},
        { pax: '13 PAX', price: '₱750.00'},
        { pax: '14 PAX', price: '₱650.00'}

      ],      
      inclusions: [
        'Private Air-conditioned Vehicle with fuel',
        'Driver Guide',
        'Boat Rental',
        'Boatman Fee',
        'Local Guide',
        'Entrance Fee'
      ],
      itineraries: [],
      exclusions: [],
      notes: [],
      optional_activities: []
    }, 
    {
      id: 6,
      tour_type: 'anda',
      title: 'Anda and Candijay Tour',
      location: 'Bohol, Philippines',
      description: 'The Anda and Candijay Tour takes you on a journey to some of Bohol’s most scenic and tranquil destinations. Your adventure starts in Anda, a charming coastal town renowned for its powdery white sand beaches and crystal-clear turquoise waters, perfect for relaxing, swimming, or snorkeling among vibrant coral reefs. Anda also boasts breathtaking cliffside views and fascinating natural caves waiting to be explored. The tour then heads to Candijay, where you’ll discover the stunning Can-umantad Falls—Bohol’s tallest waterfall—cascading gracefully amid lush greenery. You’ll also visit the Cadapdapan Rice Terraces, where terraced green fields create a picturesque view of rural life. This tour blends beach relaxation, natural beauty, and cultural landscapes, offering a well-rounded experience of Bohol’s captivating countryside.',
      image: andaImage,
      price: `₱600.00`,
      bookings: '500++ booked',
      details: [
        'Suggested Pick-up Time: 6:30 AM',
        'Tour Duration: 8-9 hours',
        'This rate only applies if your hotel is within the Panglao or Tagbilaran areas'
      ],
      pricingPerPax: [
        { pax: '1 PAX', price: '₱5,600.00' },
        { pax: '2 PAX', price: '₱2,950.00' },
        { pax: '3 PAX', price: '₱2,150.00' },
        { pax: '4 PAX', price: '₱1,900.00' },
        { pax: '5 PAX', price: '₱1,800.00' },
        { pax: '6 PAX', price: '₱1,600.00' },
        { pax: '7 PAX', price: '₱1,400.00' },
        { pax: '8 PAX', price: '₱1,300.00' },
        { pax: '9 PAX', price: '₱1,200.00' },
        { pax: '10 PAX', price: '₱1,100.00'},
        { pax: '11 PAX', price: '₱900.00'},
        { pax: '12 PAX', price: '₱800.00'},
        { pax: '13 PAX', price: '₱700.00'},
        { pax: '14 PAX', price: '₱600.00'}

      ],      
      inclusions: [
        'Private Air-conditioned Vehicle with fuel',
        'Park’s Entrance Fees',
        'Driver Guide',
        'Habal-habal or motorcycle'
      ],
      itineraries: [
        'Anda Quinale Beach',
        'Cadapdapan Rice Terraces',
        'Kumbento Cave',
        'Can-umantad Falls',
        'Cabagnow pool cave'        
      ],
      exclusions: [
        'Lunch'
      ],
      notes: [],
      optional_activities: []
    },       
    {
      id: 7,
      tour_type: 'lila',
      title: 'Whale Shark Snorkeling',
      location: 'Bohol, Philippines',
      description: 'The Whale Shark Snorkeling Experience in Bohol provides a unique chance to swim alongside the ocean\'s gentle giants. Situated off the island’s northern coast, this tour begins with a briefing on whale shark behavior and guidelines for responsible snorkeling. Once you’re in the water, you’ll witness the breathtaking sight of these majestic creatures gliding gracefully nearby. Despite their immense size, whale sharks are remarkably peaceful, and this tour allows you to interact with them safely and sustainably in their natural environment. It’s an exhilarating and humbling experience, connecting you to Bohol’s extraordinary marine life.',
      image: whaleImage,
      price: `₱1,000.00`,
      bookings: '500++ booked',
      details: [
        'Suggested Pick-up Time: 6:30 AM',
        'Tour Duration: 4-5 hours',
        'This rate only applies if your hotel is within the Panglao or Tagbilaran areas'
      ],
      pricingPerPax: [
        { pax: '1 PAX', price: '₱3,800.00' },
        { pax: '2 PAX', price: '₱2,250.00' },
        { pax: '3 PAX', price: '₱1,950.00' },
        { pax: '4 PAX', price: '₱1,750.00' },
        { pax: '5 PAX', price: '₱1,550.00' },
        { pax: '6 PAX', price: '₱1,450.00' },
        { pax: '7 PAX', price: '₱1,350.00' },
        { pax: '8 PAX', price: '₱1,250.00' },
        { pax: '9 PAX', price: '₱1,150.00' },
        { pax: '10 PAX', price: '₱1,000.00'}
      ],      
      inclusions: [
        'Private Air-conditioned Vehicle with Fuel',
        'Entrance Fees',
        'Driver Guide',
        'Boat Rental',
        'Boatman Fee',
        'Local Guide',
        'Life vest & Snorkel mask'
      ],
      itineraries: [],
      exclusions: [],
      notes: [],
      optional_activities: [
        'Chocolate hills ATV guided Tour ₱700 per head for 30mins ₱1,100 per head for 1hr',
        'Loboc Lunch buffet floating Restaurant (Rioverde) ₱1000 per head',
        'Sevilla Hanging bridge ₱50 per head',
        'Sikatuna Mirror of the World 600 additional for the vehicle and Fuel, ₱170-190 per head for entrance fees.'
      ]
    },           
  ];
  
  export default toursData;
  