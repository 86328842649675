import React from 'react';

/**
 * Helper function to bold specific phrases in a description.
 * @param {string} description - The description text where phrases will be bolded.
 * @param {string[]} phrasesToBold - An array of phrases to bold in the description.
 * @returns {JSX.Element[]} - Array of JSX elements with phrases wrapped in <b> tags.
 */
export const formatDescription = (description, phrasesToBold) => {
  const escapedPhrases = phrasesToBold.map(phrase => phrase.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
  const pattern = new RegExp(`(${escapedPhrases.join('|')})`, 'gi'); // case-insensitive
  const parts = description.split(pattern);

  return parts.map((part, index) =>
    phrasesToBold.includes(part) ? <b key={index}>{part}</b> : part
  );
};
